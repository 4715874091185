(function () {
  'use strict';

  class CloneResourceCtrl {
    constructor($mdDialog, Modules, CloneResourceFlow) {
      let vm = this;
      vm.$mdDialog = $mdDialog;
      vm.Modules = Modules;
      vm.node = CloneResourceFlow.getNode();
    }
    cancel() {
      let vm = this;
      vm.$mdDialog.cancel();
    }
    hide(data) {
      let vm = this;
      vm.$mdDialog.hide(data);
    }
    submit(form) {
      let vm = this;
      if (form.$valid) {
        vm.hide(vm.clone);
      }
    }
  }

  /**
   * @ngdoc object
   * @name components.controller:LoginCtrl
   *
   * @description
   *
   */
  angular
    .module('components')
    .controller('CloneResourceCtrl', CloneResourceCtrl);
}());
